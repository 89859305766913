import React from "react";
import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import { Link, navigate } from "gatsby";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import SEO from "../components/seo";
import Layout from "../components/layout";
import { getNestedValue } from "../utils/utils";

let parentGlobal = false;

export default ({ data, location }) => {
  const post = data.markdownRemark;
  // Figure out the current menu based on path.
  const pathArray = location.pathname.split("/");
  const relevantItems = pathArray.slice(2);
  const currentMenu =
    relevantItems[0] === "excel-kurssi-netissa" ? "online" : "advanced";

  const courseInfo = loadCourseInfo(currentMenu, data);
  // get parent key, such as Kurssin esittely or Käyttöliittymä
  const menuMainLevelKey = getMenuMainLevelKey(
    location.pathname,
    data.allMetadataJson.nodes[0][currentMenu].nodes
  );
  const initialActiveKey =
    menuMainLevelKey + removeTrailingSlash(location.pathname);
  const isOnlineCourseHome = post.frontmatter.variant === "frontpage";
  // compareSlashInsensitive(
  //   location.pathname,
  //   excelOnlineCourseFrontPageUrl
  // );
  const mainTitle = post.frontmatter.title;

  const description = getNestedValue(
    ["frontmatter", "meta", "description"],
    post
  );
  return (
    <>
      <SEO title={mainTitle} description={description} />
      <Layout
        branding="onlinecourse"
        mainTitle={mainTitle}
        mainTitleClasses={"mt-3 mb-3"}
        headerNavLinkStyle={{ color: "white" }}
        headerBgStyles={{
          backgroundColor: "#019999",
        }}
        tagLine={
          <TagLine
            currentMenu={currentMenu}
            isOnlineCourseHome={isOnlineCourseHome}
          />
        }
        location={location}
      >
        <div className="container py-4">
          <div className="row justify-content-center">
            <div className="col-12">
              <VideoPlayer
                player={post.frontmatter.player}
                playerBuildName={post.frontmatter.player_build_name}
                videoUrl={post.frontmatter.video_url}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <nav>
                <TreeMenu
                  hasSearch={false}
                  initialActiveKey={initialActiveKey}
                  initialOpenNodes={[menuMainLevelKey]}
                  data={data.allMetadataJson.nodes[0][currentMenu].nodes}
                >
                  {({ items }) => (
                    <ul className="tree-item-group">
                      {items.map((props, index) => {
                        return (
                          <ItemComponent
                            {...props}
                            closedIcon="+"
                            onClick={e => {
                              if (props.hasNodes) {
                                // Force open/close items if item has child nodes
                                props.onClick();
                                props.hasNodes &&
                                  props.toggleNode &&
                                  props.toggleNode();
                                e.stopPropagation();
                              } else {
                                navigate(props.url);
                              }
                            }}
                          />
                        );
                      })}
                    </ul>
                  )}
                </TreeMenu>
              </nav>
            </div>
            <div className="col-md-8">
              <Tabs>
                <TabList>
                  <Tab>Videon puhe tekstinä</Tab>
                  <Tab>Kurssin kuvaus</Tab>
                  <Tab>Harjoitustiedostot</Tab>
                </TabList>

                <TabPanel>
                  <div dangerouslySetInnerHTML={{ __html: post.html }} />
                </TabPanel>
                <TabPanel>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: courseInfo.description,
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: courseInfo.exerciseText,
                    }}
                  />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

function VideoPlayer(props) {
  let videoPlayerAsJSX;
  const { player, playerBuildName, videoUrl } = props;
  if (player === "camtasia") {
    const pathToPlayer =
      `/videot/${playerBuildName}/${playerBuildName}_player.html` +
      `?embedIFrameId=embeddedSmartPlayerInstance`;
    videoPlayerAsJSX = (
      <iframe
        allowFullScreen
        title="Course video"
        className="tscplayer_inline"
        id="embeddedSmartPlayerInstance"
        scrolling="no"
        src={pathToPlayer}
        frameBorder="0"
      ></iframe>
    );
  } else {
    videoPlayerAsJSX = (
      <iframe
        id="videoplayer"
        title="Course intro video"
        src={videoUrl}
        width="853"
        height="480"
        frameBorder="0"
      ></iframe>
    );
  }
  return <div className="videoWrapper">{videoPlayerAsJSX}</div>;
}

function TagLine(props) {
  const { isOnlineCourseHome, currentMenu } = props;
  let linkText;
  let linkUrl;
  if (isOnlineCourseHome) {
    return null;
  }
  if (currentMenu === "online") {
    linkText = "Excel-peruskurssi netissä";
    linkUrl = "/fi/excel-kurssi-netissa";
  }
  if (currentMenu === "advanced") {
    linkText = "Excel-jatkokurssi";
    linkUrl = "/fi/excel-jatkokurssi";
  }
  const taglineAsJSX = (
    <div className="tagLine-online">
      Kurssilta: <Link to={linkUrl}>{linkText}</Link>
    </div>
  );
  return taglineAsJSX;
}

function loadCourseInfo(currentMenu, data) {
  let courseData = {};
  if (currentMenu === "online") {
    courseData.exerciseText = data.exerciseSnippet.html;
    courseData.description = data.courseDescriptionSnippet.html;
  }
  if (currentMenu === "advanced") {
    courseData.exerciseText = data.excelAdvancedExerciseSnippet.html;
    courseData.description = data.excelAdvancedCourseDescriptionSnippet.html;
  }
  return courseData;
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        player
        player_build_name
        video_url
        variant
        meta {
          description
        }
      }
    }
    allMetadataJson {
      nodes {
        advanced {
          key
          label
          nodes {
            key
            label
            nodes {
              key
              label
              url
            }
          }
        }
        online {
          key
          label
          nodes {
            key
            label
            nodes {
              key
              label
              url
            }
          }
        }
      }
    }
    courseDescriptionSnippet: markdownRemark(
      frontmatter: {
        type: { eq: "snippet" }
        id: { eq: "online-course-description" }
      }
    ) {
      html
    }
    exerciseSnippet: markdownRemark(
      frontmatter: { type: { eq: "snippet" }, id: { eq: "exercise-files" } }
    ) {
      html
    }
    excelAdvancedExerciseSnippet: markdownRemark(
      frontmatter: {
        type: { eq: "snippet" }
        id: { eq: "excel-advanced-exercise-files" }
      }
    ) {
      html
    }
    excelAdvancedCourseDescriptionSnippet: markdownRemark(
      frontmatter: {
        type: { eq: "snippet" }
        id: { eq: "excel-advanced-course-description" }
      }
    ) {
      html
    }
  }
`;

function removeTrailingSlash(str) {
  // Remove trailing slash if present
  if (str.slice(-1) === "/") {
    str = str.slice(0, -1);
  }
  return str;
}

function getMenuMainLevelKey(pathname, menuHierarchy) {
  // const pattern = /\/(?<keyw>[^/]+)$/;
  // const matchObject = pathname.match(pattern);
  // let currentMenuItemKey = 'moi';
  // if (matchObject && matchObject.groups.key) {
  //   currentMenuItemKey = matchObject.groups.key;
  // }

  // Remove slash in beginning of the string and trailing slash if it exists
  const currentMenuItemKey = removeTrailingSlash(pathname.substr(1));

  // Recursively search through menu hierarchy and get the parent
  let parent = recursiveFindParent(
    menuHierarchy,
    currentMenuItemKey,
    "no-parent"
  );
  return parent;
}

function recursiveFindParent(menuHierarchy, keyToBeFound, parentKey) {
  if (
    menuHierarchy.some(element => {
      if (element.key && element.key === keyToBeFound) {
        parentGlobal = parentKey;
        return true;
      } else {
        if (element.nodes) {
          return recursiveFindParent(element.nodes, keyToBeFound, element.key);
        }
      }
    })
  ) {
    return parentGlobal;
  }
  return false;
}
